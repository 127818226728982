import './style.css';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as MenuBars } from '../../images/bars-regular.svg';

function Header(props) {
    window.onscroll = function() { scrollFunction() };

    return (
        <header>
            <nav id="main-nav">
                <ul>
                    <li><Link smooth to="/#top" onClick={removeMobileNav}><Logo className="logo"/></Link></li>
                    <li><Link smooth to="/#top" onClick={removeMobileNav}>Home</Link></li>
                    <li><Link smooth to="/#services-section" onClick={removeMobileNav} scroll={el => scrollWithOffset(el)}>Services</Link></li>
                    <li><Link smooth to="/#our-references-section" onClick={removeMobileNav} scroll={el => scrollWithOffset(el)}>Referenzen</Link></li>
                    <li><Link smooth to="/#about-us-section" onClick={removeMobileNav} scroll={el => scrollWithOffset(el)}>Über Uns</Link></li>
                    <li><Link smooth to="/#contact-section" onClick={removeMobileNav} scroll={el => scrollWithOffset(el)}>Kontakt</Link></li>
                    <li onClick={toggleMobileNav}><MenuBars className="menu-bars"/></li>
                </ul>
            </nav>
        </header>
    );
}

function scrollWithOffset(el) {
    const isFixedHeader = document.getElementsByTagName("header")[0].classList.contains("fixed-header");
    var offsetVar = isFixedHeader ? 30 : 51;
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -(offsetVar + (2 * parseFloat(getComputedStyle(document.documentElement).fontSize)));

    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementsByTagName("header")[0].classList.add("fixed-header");
        document.getElementsByTagName("main")[0].classList.add("fixed-header");
    } else {
        document.getElementsByTagName("header")[0].classList.remove("fixed-header");
        document.getElementsByTagName("main")[0].classList.remove("fixed-header");
    }
}

function toggleMobileNav() {
    document.getElementsByTagName("header")[0].classList.toggle("mobile-nav-open");
    document.body.classList.toggle("no-scroll");
}

function removeMobileNav() {
    document.getElementsByTagName("header")[0].classList.remove("mobile-nav-open");
    document.body.classList.remove("no-scroll");
}

export { Header };