import React, { useEffect } from "react";
import { Layout } from "../../components/Layout";
import {AudioPlayer} from "../../components/AudioPlayer";
import Koenigsretter_Hoerprobe from "../../audio/000_Muenter_Dynastie_Sample.mp3";
import Finley_Hoerprobe from "../../audio/000_Korn_Finleys_Reise_nach_Andaria_Sample.mp3";
import Better_Life_Hoerprobe from "../../audio/000_Korn_Better_Life_1_Sample.mp3";
import Bork_Hoerprobe from "../../audio/000_Schiller_Der_kleine_Bork_Sample.mp3";
import Koenigsretter_Cover from '../../images/cover/9783986100018.webp';
import Finley_Cover from '../../images/cover/9783986100032.webp';
import Better_Life_Cover from '../../images/cover/9783986100025.webp';
import Bork_Cover from '../../images/cover/9783986100056.webp';

function AudioSamples(props) {
    useEffect(() => {
        document.title = "SoundSquirrel | Hörproben";
    });

    const playlist = [
        {
            "audio": Koenigsretter_Hoerprobe,
            "img": Koenigsretter_Cover,
            "title": "Königsretter (Dynastie I)",
            "tag": "Hörbuch",
            "length": "5:00"
        },
        {
            "audio": Finley_Hoerprobe,
            "img": Finley_Cover,
            "title": "Finleys Reise nach Andaria",
            "tag": "Hörbuch",
            "length": "5:00"
        },
        {
            "audio": Better_Life_Hoerprobe,
            "img": Better_Life_Cover,
            "title": "Better Life (Teil 1) - Ausgelöscht",
            "tag": "Hörbuch",
            "length": "5:00"
        },
        {
            "audio": Bork_Hoerprobe,
            "img": Bork_Cover,
            "title": "Der kleine Bork",
            "tag": "Hörbuch",
            "length": "2:47"
        }
    ];

    return (
        <Layout>
            <main className="text-page">
                <h1>Hörproben</h1>

                <div style={{margin: "auto", width: "100%", maxWidth: "550px"}}>
                    <AudioPlayer playlist={playlist} pixel={props.pixel} />
                </div>
            </main>
        </Layout>
);
}

export default AudioSamples;