import './style.css';
import React, { useState, useRef, useEffect } from 'react';
// SVG'S
import { ReactComponent as Play } from "../../images/player/play-circle-duotone.svg";
import { ReactComponent as Pause } from "../../images/player/pause-circle-duotone.svg";
import { ReactComponent as Forward } from "../../images/player/forward-duotone.svg";
import { ReactComponent as Backward } from "../../images/player/backward-duotone.svg";

function AudioPlayer(props) {
    const playlist = props.playlist;

    const [button, setButton] = useState(<Play className="play-pause-btn" onClick={playPauseAudio}/>);
    const [counter, _setCounter] = useState(0);
    const [audioPlayer] = useState(new Audio());
    const [percentagePlayed, setPercentagePlayed] = useState(0);

    const currentCountRef = useRef(counter);
    const audioPlayerRef = useRef(audioPlayer);

    const playlistItems = playlist.map((item, index) =>
        <div key={index} className={(index === currentCountRef.current) ? "playlist-item active" : "playlist-item"} onClick={(e) => selectSpecific(index, e)}>
            <div className="number">{index + 1}</div>
            <div className="title">{item.title}</div>
            <div className="duration">{item.length}</div>
        </div>
    );

    audioPlayerRef.current.addEventListener("ended", function(){
        audioPlayerRef.current.currentTime = 0;
        setButton(<Play className="play-pause-btn" onClick={playPauseAudio}/>);
    });

    useEffect(() => {
        audioPlayerRef.current.src = playlist[currentCountRef.current].audio;

        const interval = setInterval(() => {
            var percent = (audioPlayerRef.current.currentTime / audioPlayerRef.current.duration) * 100;
            setPercentagePlayed(percent);
        }, 300);
        return () => clearInterval(interval);
    }, [playlist]);

    const setCounter = x => {
        currentCountRef.current = x;
        _setCounter(x);
    };

    function playPauseAudio() {
        (audioPlayerRef.current.paused) ? playAudio() : pauseAudio();
    }

    function playAudio() {
        if (audioPlayerRef.current.paused) {
            audioPlayerRef.current.play();
            setButton(<Pause className="play-pause-btn" onClick={playPauseAudio}/>);

            props.pixel.track('ViewContent', {content_name: playlist[currentCountRef.current].title});
        }
    }

    function pauseAudio() {
        if (!audioPlayerRef.current.paused) {
            audioPlayerRef.current.pause();
            setButton(<Play className="play-pause-btn" onClick={playPauseAudio}/>);
        }
    }

    function nextTrack(e) {
        var target = (e.target.ownerSVGElement) ? e.target.ownerSVGElement : e.target;

        if (!target.classList.contains("disabled") && currentCountRef.current !== playlist.length - 1) {
            pauseAudio();
            audioPlayerRef.current.currentTime = 0;
            setCounter(counter + 1);
            audioPlayerRef.current.src = playlist[currentCountRef.current].audio;
            playAudio();
        }
    }

    function previousTrack(e) {
        var target = (e.target.ownerSVGElement) ? e.target.ownerSVGElement : e.target;

        if (!target.classList.contains("disabled") && currentCountRef.current !== 0) {
            pauseAudio();
            audioPlayerRef.current.currentTime = 0;
            setCounter(counter - 1);
            audioPlayerRef.current.src = playlist[currentCountRef.current].audio;
            playAudio();
        }
    }

    function selectSpecific(index, e) {
        pauseAudio();
        audioPlayerRef.current.currentTime = 0;
        setCounter(index);
        audioPlayerRef.current.src = playlist[currentCountRef.current].audio;
        playAudio();
    }

    function scrub(e) {
        var width = e.target.classList.contains("player-progress-bar-container") ? e.target.clientWidth : e.target.parentElement.clientWidth;
        var posX = e.nativeEvent.offsetX;
        
        var percent = posX / width;

        audioPlayerRef.current.currentTime = audioPlayerRef.current.duration * percent;
    }

    return(
        <div className="player">
            <div className="player-header">
                <img src={playlist[counter].img} className="background" aria-hidden="true" alt=""/>
                <div className="front">
                    <img src={playlist[counter].img} className="avatar" aria-hidden="true" alt=""/>
                    <div className="information">
                        <div className="title">{playlist[counter].title}</div>
                        <div className="tag">{playlist[counter].tag}</div>
                    </div>
                </div>
            </div>

            <div className="player-progress-bar-container" onClick={scrub}>
                <div className="player-progress-bar" style={{width: percentagePlayed + "%"}} />
            </div>

            <div className="player-controls">
                <Backward className={(counter === 0) ? "fwd-bwd-btn disabled" : "fwd-bwd-btn"} onClick={previousTrack}/>
                {button}
                <Forward className={(counter === (playlist.length - 1)) ? "fwd-bwd-btn disabled" : "fwd-bwd-btn"} onClick={nextTrack}/>
            </div>

            <div className="player-playlist">
                {playlistItems}
            </div>
        </div>
    );
}

export { AudioPlayer }