import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Amplify from "aws-amplify";
import amplifyConfig from "./amplify-config.json";
// Pages
import Home from './pages/home/home';
import DataProtection from './pages/data-protection/data-protection';
import Imprint from './pages/imprint/imprint';
import AudioSamples from './pages/audio-samples/audio-samples';
import ReactPixel from 'react-facebook-pixel';

Amplify.configure(amplifyConfig);

const options = {
  debug: true // enable logs
};

ReactPixel.init('898581611007523', undefined, options);
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <Router> 
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home pixel={ReactPixel} />
        </Route>
        <Route exact path="/datenschutz">
          <DataProtection />
        </Route>
        <Route exact path="/impressum">
          <Imprint />
        </Route>
        <Route exact path="/hoerproben">
          <AudioSamples pixel={ReactPixel} />
        </Route>
      </Switch>
    </Router> 
  </React.StrictMode>,

  document.getElementById('root')
);   