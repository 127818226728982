import React from 'react';
import { Header } from '../Header/';
import { Footer } from '../Footer';

function Layout(props) {
    return(
        <React.Fragment>
            <Header pixel={props.pixel}/>
            
            { props.children }

            <Footer pixel={props.pixel}/>
        </React.Fragment>
    );
}

export { Layout }