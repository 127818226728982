import './style.css';
import React from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as InstagramLogo } from '../../images/instagram-brands.svg';
import { ReactComponent as FacebookLogo } from '../../images/facebook-square-brands.svg';

function Footer(props) {
    return (
        <footer>
            <div className="footer-grid">
                <Logo className="logo"/>

                <ul>
                    <span>Referenzen</span>
                    <li>Mercedes Benz</li>
                    <li>Indeed</li>
                    <li>audible</li>
                    <li>Generali Versicherung</li>
                    <li>About You</li>
                </ul>

                <address>
                    <span>Kontakt</span>
                    SoundSquirrel UG (haftungsbeschränkt)<br/>
                    c/o Marcel Hallensleben<br/>
                    Thomaestr. 13<br/>
                    38118 Braunschweig<br/>
                    <br/>
                    <a href="mailto:info@soundsquirrel.de" onClick={ () => props.pixel.track('Lead') }>info@soundsquirrel.de</a>
                </address>

                <ul>
                    <span>Social Media</span>
                    <a href="https://www.instagram.com/soundsquirrel.de/" target="_blank" rel="noreferrer"><InstagramLogo className="social-link"/></a>
                    <a href="https://www.facebook.com/SoundSquirrelDE" target="_blank" rel="noreferrer"><FacebookLogo className="social-link"/></a>
                </ul>

                <nav id="footer-nav">
                    <ul>
                        <li><Link to="/datenschutz">Datenschutz</Link></li>
                        <li><Link to="/impressum">Impressum</Link></li>
                    </ul>
                </nav>
            </div>
        </footer>
    );
}

export { Footer }