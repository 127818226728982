import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { HashLink as Link} from 'react-router-hash-link';
import { AudioPlayer } from "../../components/AudioPlayer";
import { API } from 'aws-amplify';
import useProgressiveImg from "../../components/Hooks/useProgressiveImg";
// SVG'S & Images
import { ReactComponent as Console } from "../../images/console_illustration.svg";
import { ReactComponent as Player } from "../../images/player_illustration.svg";
import { ReactComponent as Wave } from "../../images/wave_illustration.svg";
import HeroShotTiny from "../../images/studio/hero_shot_tiny.webp";
import HeroShot from "../../images/studio/hero_shot.webp";
import Headshot from "../../images/marcel_hallensleben.webp";
// Testimonials
import MaxRose from "../../images/testimonials/max_rose.webp";
import PiaRhonaSaxe from "../../images/testimonials/pia-rhona_saxe.webp";
import LillithKorn from "../../images/testimonials/lillith_korn.webp";
// Cover
import Cover1 from "../../images/cover/sommer_1986_cover.webp";
import Cover2 from "../../images/cover/fluchsammler_cover.webp";
import Cover3 from "../../images/cover/mercenary_cover.webp";
import Cover4 from "../../images/cover/finleys_reise_nach_pandemia_cover.webp";
import Cover5 from "../../images/cover/bei_dir_bin_ich_zu_hause_cover.webp";
import Cover6 from "../../images/cover/hunter_cover.webp";
import Cover7 from "../../images/cover/kalte_schatten_cover.webp";
import Cover8 from "../../images/cover/nanos_abenteuer_cover.webp";
import Cover9 from "../../images/cover/ahren_cover.webp";
import Cover10 from "../../images/cover/alte_goetter_cover.webp";
import Cover11 from "../../images/cover/vielleicht_beim_dritten_date_cover.webp";
import Cover12 from "../../images/cover/koenigsretter_cover.webp";
import Cover13 from "../../images/cover/zwergberg_memento_mori_cover.webp";
// Referenzen
import MercedesBenz from "../../images/references/mercedes_benz.webp";
import Indeed from "../../images/references/indeed.webp";
import AboutYou from "../../images/references/about_you.webp";
import Generali from "../../images/references/generali.webp";
// Samples
import Alte_Goetter_Hoerprobe from "../../audio/Alte_Goetter_Hoerprobe.mp3";
import Koenigsretter_Dynastie_1_Hoerprobe from "../../audio/Koenigsretter_Dynastie_1_Hoerprobe.mp3";
import Bei_dir_bin_ich_zu_Hause_Hoerprobe from "../../audio/Bei_dir_bin_ich_zu_Hause_Hoerprobe.mp3";
import Nanos_Abenteuer_Hoerprobe from "../../audio/Nanos_Abenteuer_Hoerprobe.mp3";
import Zwergberg_Hoerprobe from "../../audio/Zwergberg_Memento_Mori_Hoerprobe.mp3";

function Home(props) {
    const playlist = [
        {
            "audio": Nanos_Abenteuer_Hoerprobe,
            "img": Cover8,
            "title": "Das Dorf interaktiv - Nanos Abenteuer",
            "tag": "Hörbuch",
            "length": "0:34"
        },
        {
            "audio": Zwergberg_Hoerprobe,
            "img": Cover13,
            "title": "Zwergberg: Memento Mori",
            "tag": "Hörspiel",
            "length": "4:27"
        },
        {
            "audio": Alte_Goetter_Hoerprobe,
            "img": Cover10,
            "title": "Alte Götter",
            "tag": "Hörbuch",
            "length": "5:00"
        },
        {
            "audio": Bei_dir_bin_ich_zu_Hause_Hoerprobe,
            "img": Cover5,
            "title": "Bei dir bin ich zu Hause",
            "tag": "Hörbuch",
            "length": "5:02"
        },
        {
            "audio": Koenigsretter_Dynastie_1_Hoerprobe,
            "img": Cover12,
            "title": "Königsretter (Dynastie I)",
            "tag": "Hörbuch",
            "length": "5:10"
        }
    ];

    useEffect(() => {
        document.title = "SoundSquirrel | Produktion von Hörbüchern für Verlage und Autoren";
    });

    const BlurredHeroImage = () => {
        const [src, { blur }] = useProgressiveImg(HeroShotTiny, HeroShot);
        
        return (
            <img aria-hidden="true" alt="" role="presentation" className="hero-image" src={src} style={{ filter: blur ? "blur(20px)" : "none", transform: blur ? "scale(1.1)" : "none", transition: blur ? "none" : "filter 0.1s ease-out" }} />
        );
    };

    const [formName, setFormName] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formMessage, setFormMessage] = useState("");

    function submitContactForm(event) {
        event.preventDefault();
    
        const payload = {
            name: formName,
            email: formEmail,
            message: formMessage
        }

        API.post("SQ_Website_API", "/message", { body: payload })
        .then(response => {
            const statusCode = response.statusCode;
            const message = response.body;
            const messageContainer = document.getElementById("contact-form-messages");

            messageContainer.innerHTML = message;

            if (statusCode === 200) {
                messageContainer.classList.add("success");
                setFormName("");
                setFormEmail("");
                setFormMessage("");
            } else if (statusCode === 400) {
                messageContainer.classList.add("error");
            }
        });

        props.pixel.track('Lead');
    }

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -(30 + (2 * parseFloat(getComputedStyle(document.documentElement).fontSize))); 
    
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <Layout pixel={props.pixel}>
            <main>
                {/* ===== Section 1: Above the fold ===== */}
                <section className="main-section hero">
                    <h1>Professionelle Hörbuchproduktionen für Verlage &amp; Selfpublisher</h1>
                    <Link smooth to="/#contact-section" onClick={ () => props.pixel.track('ViewContent', {content_name: "Hero Button (Jetzt anfragen)"}) } scroll={el => scrollWithOffset(el)} className="btn-accent btn-hero">Jetzt anfragen</Link>
                    <BlurredHeroImage />
                </section>

                {/* ===== Section 2: Unsere Services ===== */}
                <section id="services-section">
                    <p className="section-title">Unsere Services</p>
                    <h2>Was Sie bei uns bekommen</h2>

                    <section className="first-sub-section">
                        <Wave className="services-illustration"/>
                        <h3>Sprachregie</h3>
                        <p>
                        Einen Film ohne Regisseur:in gibt es nicht, entsprechend wichtig ist eine professionelle Sprachregie bei der 
                        Erstellung eines Hörbuchs. Als Bindeglied zwischen Autor:in, Text und Sprecher:in sorgen wir für ein harmonisches 
                        Gesamtwerk und erhöhen so die Hörerbindung.
                        </p>
                    </section>

                    <section className="second-sub-section">
                        <Player className="services-illustration"/>
                        <h3>Hörbuchproduktion</h3>
                        <p>
                            Wir kümmern uns darum, dass aus Ihrem Buch ein professionelles Hörbuch entsteht. Wir beraten Sie von Beginn an 
                            kompetent und helfen Ihnen dabei, die beste Stimme zu finden. Die Aufnahme findet unter Sprachregie in unserem
                            Hörbuchstudio statt und im Rahmen der Nachbearbeitung bereiten wir die Aufnahme optimal für den Vertrieb vor.
                        </p>
                    </section>

                    <section className="third-sub-section">
                        <Console className="services-illustration"/>
                        <h3>Hörbuch-Mastering</h3>
                        <p>
                            Im Rahmen des Hörbuch-Masterings sorgen wir für ein ausgewogenes und optimales Klangbild auf allen Abspielgeräten. 
                            Zugleich wird das Hörbuch an die technischen Anforderungen des Vertriebswegs angepasst, um Ihnen eine zeitnahe und
                            verzögerungsfreie Veröffentlichung zu garantieren.
                        </p>
                    </section>
                </section>

                {/* ===== Section 3: Unsere Referenzen ===== */}
                <section id="our-references-section">
                    <h2>
                        Unsere<br/>
                        <span className="accent">Referenzen</span>
                    </h2>
                    
                    <AudioPlayer playlist={playlist} pixel={props.pixel} />

                    <Link smooth to="/#contact-section" onClick={ () => props.pixel.track('ViewContent', {content_name: "Referenz Button (Anfrage stellen)"}) } scroll={el => scrollWithOffset(el)} className="btn-accent">Anfrage stellen</Link>

                    {/* --- Cover-Bilder --- */}
                    <img aria-hidden="true" alt="" width="280" height="280" role="presentation" className="reference-cover-image pos-1" src={Cover1}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-2" src={Cover2}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-3" src={Cover3}/>
                    <img aria-hidden="true" alt="" width="280" height="280" role="presentation" className="reference-cover-image pos-4" src={Cover4}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-5" src={Cover5}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-6" src={Cover6}/>
                    <img aria-hidden="true" alt="" width="280" height="280" role="presentation" className="reference-cover-image pos-7" src={Cover7}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-8" src={Cover8}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-9" src={Cover9}/>
                    <img aria-hidden="true" alt="" width="280" height="280" role="presentation" className="reference-cover-image pos-10" src={Cover10}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-11" src={Cover11}/>
                    <img aria-hidden="true" alt="" width="140" height="140" role="presentation" className="reference-cover-image small pos-12" src={Cover12}/>
                </section>

                {/* ===== Section 4: Testimonials ===== */}
                <section id="testimonial-section">
                    {/* <div className="testimonial-grid"></div> */}
                    <section className="first-testimonial">
                        <img src={PiaRhonaSaxe} className="testimonial-image" width="190" height="190" alt="Ein Porträtfoto der Hörbuchsprecherin Pia-Rhona Saxe."></img>
                        <p className="testimonial-role">Hörbuchsprecherin</p>
                        <h3>Pia-Rhona Saxe</h3>
                        <p>
                            "Marcel begegnet einem immer sehr freundlich und professionell. Außerdem steht er auch den Sprechern immer ausführlich 
                            mit Rat und Tat zur Seite. Als ich Hilfe beim Konzipieren meines Heimstudios brauchte, hat er sich sofort Zeit genommen 
                            und mir weiter geholfen, sodass in Zukunft die Zusammenarbeit auch über Remote-Aufnahmen möglich sein wird. Insgesamt 
                            einfach ein super angenehmer Kontakt."
                        </p>
                    </section>

                    <section className="second-testimonial">
                        <img src={MaxRose} className="testimonial-image" width="190" height="190" alt="Ein Porträtfoto von Max Rose, Geschäftsführer der audory UG."></img>
                        <p className="testimonial-role">audory UG - Geschäftsführer</p>
                        <h3>Max Rose</h3>
                        <p>
                            "Marcel ist bei jeder Produktion mit seinem Kopf dabei und versteht sofort den Kontext. Selbst, als uns 
                            einige Stolpersteine das Leben schwer gemacht haben, hat uns Marcel mit seinem kühlen Kopf die Produktion gerettet. 
                            Klasse Zusammenarbeit!"
                        </p>
                    </section>
 
                    <section className="third-testimonial">
                        <img src={LillithKorn} className="testimonial-image" width="190" height="190" alt="Ein Porträtfoto der Autoin Lillith Korn."></img>
                        <p className="testimonial-role">Autorin</p>
                        <h3>Lillith Korn</h3>
                        <p>
                            "Marcel ist ein super sympathischer Mensch, bei dem sich jeder sofort wohlfühlt, und am Endergebnis hört man das auch. 
                            Wer ein unkompliziertes Tonstudio mit einer Affinität zu Hörbüchern sucht ist bei SoundSquirrel genau richtig. 
                            Ich würde jederzeit wieder mit ihm Zusammenarbeiten!"
                        </p>
                    </section>
                </section>

                {/* ===== Section 5: Über Uns ===== */}
                <section id="about-us-section" className="max-width-section">
                    <p className="section-title">Über Uns</p>
                    <h2>Warum wir so gut sind</h2>

                    <p>
                        SoundSquirrel produziert Hörbücher und Hörspiele für Verlage, Selfpublisher und publizierende Medienunternehmen. 
                        Und zwar mit derselben Leidenschaft, mit der sie geschrieben wurden. So erreichen wir auch für Ihre Produktion ein 
                        bestmögliches Klangerlebnis.<br />
                        <br />
                        Neben deutschen Verlagen verlassen sich auch New York Times Bestseller Autor:innen, wie Ruth Cardello auf unsere Expertise 
                        für ihre Hörbuchproduktionen. Selbst internationale Marken haben sich uns für ihr E-Learning oder ihren Podcast anvertraut,
                        denn Sprache ist unsere Expertise. Unsere Kunden schätzen dabei unsere lösungsorientierte und unkomplizierte Zusammenarbeit.<br />
                        <br />
                        Ob aufwendiger Audio-Blockbuster, fantastische Dystopie oder informatives Expertenbuch, wir freuen uns über jedes neue, 
                        spannende Projekt. Überzeugen Sie sich von unserer Arbeit und setzen Sie sich noch heute mit uns in Verbindung.
                    </p>

                    <img src={Headshot} className="headshot-image" width="300" height="300" alt="Ein Porträtfoto des Geschäftsführers Marcel Hallensleben."></img>

                    <img aria-hidden="true" alt="" width="226" height="100" className="reference-logo left top" role="presentation" src={MercedesBenz}/>
                    <img aria-hidden="true" alt="" width="226" height="100" className="reference-logo left bottom" role="presentation" src={Indeed}/>
                    <img aria-hidden="true" alt="" width="226" height="100" className="reference-logo right top" role="presentation" src={AboutYou}/>
                    <img aria-hidden="true" alt="" width="226" height="100" className="reference-logo right bottom" role="presentation" src={Generali}/>
                </section>

                {/* ===== Section 6: Kontakt ===== */}
                <section id="contact-section">
                    <div className="centered-column">
                        <h2>
                            Stellen Sie jetzt kostenlos und unverbindlich Ihre Anfrage!<br />
                            <span className="accent">Sie erreichen uns über das Kontaktformular, oder per <a href="mailto:info@soundsquirrel.de" onClick={ () => props.pixel.track('Lead') } className="contact-email">E-Mail</a>.</span>
                        </h2>
                        <form id="contact-form" onSubmit={submitContactForm}>
                            <input type="text" placeholder="Name" value={formName} onChange={e => setFormName(e.target.value)} required/>
                            <input type="email" placeholder="E-Mail-Adresse" value={formEmail} onChange={e => setFormEmail(e.target.value)} required/>
                            <textarea rows="6" placeholder="Nachricht" value={formMessage} onChange={e => setFormMessage(e.target.value)} required/>
                            <div id="contact-form-messages" className="alert" />
                            <input type="submit" className="btn-accent" value="Senden"/>
                        </form>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default Home;